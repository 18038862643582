import { Picture, pictureBuilder } from "models/Picture";
import {
  CloudDomain,
  Domain,
  DynamicPage,
  WCComponent,
  WCElement,
  WCTextElement,
  WebsiteConfiguration,
  WebsiteConfigurationFormValues,
  WebsiteConfigurationGlobal,
  WebsiteConfigurationHeader,
  WebsiteSettingsFormValues,
  WebsiteStatus,
  WebsiteStatusTask,
  websiteSettings,
} from "models/WebsiteConfiguration";
import { listFormatter } from "utils";
import { formatPicture } from "./picture";

export const formatWebsiteStatusTask = (result: any): WebsiteStatusTask => ({
  labels: result.labels,
  status: result.status,
  routes: result.routes,
});

export const formatWebsiteStatus = (result: any): WebsiteStatus => ({
  availWebsiteByClient: result.availWebsiteByClient,
  mandatoryTasks:
    result.mandatoryTasks &&
    listFormatter<WebsiteStatusTask>(
      result.mandatoryTasks,
      formatWebsiteStatusTask
    ),
  optionalTasks:
    result.optionalTasks &&
    listFormatter<WebsiteStatusTask>(
      result.optionalTasks,
      formatWebsiteStatusTask
    ),
  mandatoryTasksAreTrue: result.mandatoryTasksAreTrue,
});

export const formatWebsiteConfigurationGlobal = (
  result: any
): WebsiteConfigurationGlobal => ({
  logo:
    result.general.logo && result.general.logo.length > 0
      ? result.general.logo[0]
      : pictureBuilder(),
  logoBanner:
    result.general.logoBanner && result.general.logoBanner.length > 0
      ? result.general.logoBanner[0]
      : pictureBuilder(),
  backgroundColor: result.general.backgroundColor,
});

export const formatWebsiteConfigurationHeader = (
  result: any
): WebsiteConfigurationHeader => ({
  pictures:
    result.header.picture && result.header.picture.length > 0
      ? result.header.picture[0]
      : pictureBuilder(),
  title: {
    label: result.header.title,
    color: result.header.titleColor,
  },
});

export const formatWebsiteSettings = (result: any): websiteSettings => ({
  myDomains: result.myDomains
    ? listFormatter<Domain>(result.myDomains, formatDomain)
    : [],
});

export const formatWebsiteSettingsFormValues = (
  websiteSettings: websiteSettings
): WebsiteSettingsFormValues => ({
  myDomains: websiteSettings.myDomains,
});

export const formatCloudDomain = (result: any): CloudDomain => ({
  name: result.name,
  price: result.price,
  availability: result.availability,
});

export const formatDomain = (result: any): Domain => ({
  id: result.id,
  name: result.name,
  price: result.price,
  status: result.status,
  transferKey: result.transferKey,
});

export const formatTextElement = (result: any): WCTextElement => ({
  id: result.id,
  value: result.value,
  size: result.size,
  bold: result.bold,
  underline: result.underline,
  italic: result.italic,
  align: result.align,
});

export const formatComponent = (result: any): WCComponent => ({
  id: result.id,
  type: result.componentType,
  order: result.componentOrder,
  elements:
    result.elements &&
    listFormatter<WCElement>(result.elements, formatWCElement),
  isMandatory: result.isMandatory,
  textAdaptation: result.textAdaptation,
});

export const formatWCElement = (result: any): WCElement => ({
  id: result.id,
  order: result.elementOrder,
  pictures:
    result.pictures && listFormatter<Picture>(result.pictures, formatPicture),
  title: result.title && formatTextElement(result.title),
  text: result.text && formatTextElement(result.text),
});

export const formatDynamicPage = (result: any): DynamicPage => ({
  name: result.name,
  components:
    result.components &&
    listFormatter<WCComponent>(result.components, formatComponent),
  dynamicPage: result.dynamicPage,
});

export const formatWebsiteConfiguration = (
  result: any
): WebsiteConfiguration => ({
  template: result.template,
  theme: result.theme,
  general: result.general && formatWebsiteConfigurationGlobal(result),
  header: result.header && formatWebsiteConfigurationHeader(result),
  dynamicPages:
    result.dynamicPages &&
    listFormatter<DynamicPage>(result.dynamicPages, formatDynamicPage),
});

export const formatWebsiteConfigurationFormValues = (
  websiteConfiguration: WebsiteConfiguration
): WebsiteConfigurationFormValues => ({
  template: websiteConfiguration.template,
  theme: websiteConfiguration.theme,
  general: websiteConfiguration.general,
  header: websiteConfiguration.header,
  dynamicPages:
    websiteConfiguration.dynamicPages &&
    websiteConfiguration.dynamicPages.length > 0
      ? listFormatter<DynamicPage>(
          websiteConfiguration.dynamicPages,
          formatDynamicPage
        )
      : [],
});

export const CloudDomainBuilder = (
  values: Partial<CloudDomain> = {}
): CloudDomain => ({
  name: "",
  availability: "",
  ...values,
});
